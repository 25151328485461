<template>
  <TableList :data="users"/>
</template>

<script>
export default {
    components:{
        TableList:()=>import('./components/TableList.vue')
    },
    computed:{
        users(){
            return this.$store.state.users.users
        }
    },
    mounted(){
        this.$store.dispatch('users/users_init')
    }
}
</script>

<style>

</style>